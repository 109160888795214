import axios from "axios";
export const callSwapApi = async (params) => {
    const { tokenIn, tokenOut, amount, maxPriceImpact, gasPrice, to, preferSushi,chainId } = params;
 
  
    const queryParams = new URLSearchParams({
        chainId,
      tokenIn,
      tokenOut,
      amount,
      maxPriceImpact,
      // gasPrice,
    
      preferSushi,
    });
    if (to !== undefined) {
      queryParams.append('to', to);
    }
    if (gasPrice !== undefined) {
      queryParams.append('gasPrice', gasPrice);
    }
    const apiUrl = `https://shittyswap.com/swap/v4/${chainId}`;
  
    const url = `${apiUrl}?${queryParams.toString()}`;
  
    try {
      const response = await fetch(url, {
        method: 'GET', // assuming this is a GET request based on the URL structure
        headers: {
          'Content-Type': 'application/json',
        },
      });
  
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
  
      const data = await response.json();
      // console.log('API response:', data);
      return data; // Return data if needed for further processing
    } catch (error) {
      console.error('Error fetching data:', error);
      throw error; // Rethrow error to handle it in the calling function
    }
  };





// const apiKey = 'b96c35d0-d0cb-4d75-8843-0f5fec332a3f';
// const tokenSymbol = 'ETH'; // Replace with the symbol of the token you want to fetch (e.g., ETH for Ethereum)


// Function to fetch data from API based on symbol
export async function fetchSymbolInfo(symbol) {
  const apiUrl = `https://shittyswap.com/info?symbol=${symbol}`;

  try {
      // Make GET request to the API
      const response = await axios.get(apiUrl);
      // console.log(`${response.data['name']}responseresponse`)

      // Check if the response is successful (status code 200)
      if (response.status === 200) {
          // Handle the response data
          // console.log(response.data); // Example: Logging data to console
          // You can further process the data here as needed
         return response.data
      } else {
          console.error(`Failed to fetch data. Status code: ${response.status}`);
      }
  } catch (error) {
      // Handle errors during the request
      console.log(`hello`)
      console.error('Error fetching data:', error.message);
  }
}

// Example usage:
// const symbol = 'BTC';
// fetchSymbolInfo(symbol);






  export function toPlainString(num) {
    return ("" + +num).replace(
      /(-?)(\d*)\.?(\d*)e([+-]\d+)/,
      function (a, b, c, d, e) {
        return e < 0
          ? b + "0." + Array(1 - e - c.length).join(0) + c + d
          : b + c + d + Array(e - d.length + 1).join(0);
      }
    );
  }
  
  
  export function cutAfterDecimal(number, pos, dl, ac) {
    if (dl) {
      const limit = dl?.decimalLimit[ac] > 0 ? dl?.decimalLimit[ac] : 5;
      const res =
        number?.toString()?.indexOf('.') > -1
          ? number.toString().slice(0, number.toString().indexOf('.') + limit + 1)
          : number;
      return res;
    } else {
      const res =
        number?.toString()?.indexOf('.') > -1
          ? number.toString().slice(0, number.toString().indexOf('.') + pos + 1)
          : number;
      return res;
    }
  }