import "./App.css";
import Header from "./components/Header";
import {Routes, Route} from "react-router-dom";
import Swap from "./components/Swap";
import Tokens from "./components/Tokens";
import Docs from "./components/Docs";
// import {useConnect, useAccount} from "wagmi"
// import {MetaMaskConnector,} from "@wagmi/connectors/metaMask"
// import {coinbaseWallet} from "@wagmi/connectors/coinbaseWallet"
import  { Toaster } from 'react-hot-toast';
import { useAccount } from "wagmi";
import { getAccount } from '@wagmi/core'




// import { getDefaultConfig } from '@rainbow-me/rainbowkit';





function App() {
  // const {address, isConnected, chainId} = useAccount()
  const {address, isConnected, chainId} = getAccount()
  // console.log(`${address} account`);

  
  // const {connect} = useConnect({
  //   connector: new MetaMaskConnector(),
  // })
  // console.log(`${chainId}:::::::${isConnected}`);
  return (
    <div className="App">
   
     
    <Header/>
    <div className="mainWindow">
      <Routes>
        <Route path="/" element={<Swap isConnected={isConnected} address={address} chainId={chainId} />}/>
        <Route path="/tokens" element={<Tokens/>}/>
        <Route path="/docs" element={<Docs/>}/>
        </Routes>
        <Toaster />
    </div>
  
  


        </div>
  )
}

export default App;
