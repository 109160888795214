import React, { useState } from 'react'
import Eth from '../eth.svg'
import Logo from '../logo.png'
import {Link} from 'react-router-dom'
import { useSwitchNetwork } from 'wagmi'
import { ConnectButton } from '@rainbow-me/rainbowkit'

function Header(props) {
  // const {connect, isConnected, address} = props
  // const {chainId} = useSwitchNetwork({chainId:1})
  const [selected,setSelected] = useState("Swap")
  
  return (
    <header>
      <div className='leftH'>
        <img src={Logo} alt='eth' className='logo' />
        <Link to='/' className='link'>
        <div className='headerItem'>Swap</div>  
        </Link>
        <Link to='/tokens' className='link'>
        <div className='headerItem'>Tokens</div>
        </Link>

        <div className="dropdown headerDropdown">
  <button className="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
   {selected}
  </button>
  <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
    <li> <Link to='/' className='dropdown-item'>
        <div className='headerItem-mob' onClick={()=>{setSelected("Swap")}}>Swap</div>
        </Link></li>
    <li><Link to='/tokens' className='dropdown-item'>
        <div className='headerItem-mob' onClick={()=>{setSelected("Tokens")}}>Tokens</div>
        </Link></li>
   
  </ul>
</div>
        {/* <Link to='/docs' className='link'>
        <div className='headerItem'>Docs</div>
        </Link> */}
        </div>
      <div className='rightH'>
        {/* <div className='headerItem'>
          <img src={Eth} alt='eth' className='logo' />
          Ethereum
        </div> */}
        {/* <div className='connectButton' 
        onClick={connect}
         >{isConnected ? (address.slice(0,4) + "..." + address.slice(38)) : "Connect" }</div> */}
         <ConnectButton.Custom>
                      {({
                        account,
                        chain,
                        openAccountModal,
                        openChainModal,
                        openConnectModal,
                        authenticationStatus,
                        mounted,
                      }) => {
                        // if (account && account.address !== "") {
                        //   dispatch(
                        //     setWalletAddress({ walletAddress: account.address })
                        //   );

                        //   {
                        //     /* console.log(account, "wallet-btn"); */
                        //   }
                        // }

                        const ready =
                          mounted && authenticationStatus !== "loading";
                        const connected =
                          ready &&
                          account &&
                          chain &&
                          (!authenticationStatus ||
                            authenticationStatus === "authenticated");

                        return (
                          <>
                            <div
                              {...(!ready && {
                                "aria-hidden": true,
                                style: {
                                  opacity: 0,
                                  pointerEvents: "none",
                                  userSelect: "none",
                                },
                              })}
                            >
                              {(() => {
                                if (!connected) {
                                  return (
                                    <div className="default-btn default-btn--small connect-btn">
                                      <div
                                        className=" fw-bolder"
                                        onClick={openConnectModal}
                                        style={{ cursor: 'pointer', backgroundColor:"black", borderRadius:20, padding:"5px 10px" }}
                                      >
                                        Connect Wallet
                                      </div>
                                    </div>
                                  );
                                }

                                if (chain.unsupported) {
                                  return (
                                    <button
                                      onClick={openChainModal}
                                      type="button"
                                      className="bg-danger btn btn-danger btn-sm"
                                      style={{ cursor: 'pointer' }}
                                    >
                                      Wrong network
                                    </button>
                                  );
                                }

                                return (
                                  <div style={{ display: "flex", gap: 12 , cursor:"pointer", backgroundColor:"black", borderRadius:20, padding:"5px 10px" }} >
                                    <div className="default-btn default-btn--small connect-btn">
                                      <div
                                        className="fw-bolder"
                                        onClick={openAccountModal}
                                      >
                                        {account.displayName}
                                        {account.displayBalance
                                          ? ` (${account.displayBalance})`
                                          : ""}
                                      </div>
                                    </div>
                                  </div>
                                );
                              })()}
                            </div>
                          </>
                        );
                      }}
                    </ConnectButton.Custom>
        </div>
    </header>
  )
}

export default Header