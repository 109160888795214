import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
// import {configureChains, mainnet, WagmiConfig, createClient} from "wagmi";
// import {publicProvider} from "wagmi/providers/public"

// const {provider, webSocketProvider} = configureChains(
//   [mainnet],
//   [publicProvider()]
// )

// const client = createClient({
//   provider,
//   webSocketProvider,
//   autoConnect: true,
// })

import {
  connectorsForWallets,
  darkTheme,
  getDefaultWallets,
  RainbowKitProvider,
} from "@rainbow-me/rainbowkit";
import { configureChains, createConfig, mainnet, WagmiConfig } from "wagmi";
import { bsc, bscTestnet } from "wagmi/chains";
import { publicProvider } from "wagmi/providers/public";
import { jsonRpcProvider } from "wagmi/providers/jsonRpc";
// import { CustomerForm } from "./Components/CustomerForm";
// import { AdminView } from "./Components/AdminView";
// import { BuyToken } from "./Components/BuyToken";
import {
  coinbaseWallet,
  injectedWallet,
  metaMaskWallet,
  rainbowWallet,
  trustWallet,
  walletConnectWallet,
} from "@rainbow-me/rainbowkit/wallets";

import "@rainbow-me/rainbowkit/styles.css";


const { chains, publicClient } = configureChains(
  [mainnet],
  [publicProvider()],
  [
    jsonRpcProvider({
      rpc: (chain) => ({
        http: `${chain.rpcUrls.default.http[0]}`,
      }),
    }),
  ]
);
const projectId = "b640fb2479f1b615d16f8a39653e517a";
const connectors = connectorsForWallets([
  {
    groupName: "Recommended",
    wallets: [
      injectedWallet({ chains }),
      rainbowWallet({ projectId, chains }),
      walletConnectWallet({ projectId, chains }),
      metaMaskWallet({ projectId, chains }),
      coinbaseWallet({ chains, appName: "My RainbowKit App" }),
      trustWallet({ projectId, chains }),
    ],
  },
]);

const wagmiConfig = createConfig({
  autoConnect: true,
  connectors,
  publicClient,
});


const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    {/* <WagmiConfig client={client}> */}
    <WagmiConfig config={wagmiConfig}>
          <RainbowKitProvider
            chains={chains}
            modalSize="compact"
            theme={darkTheme()}
          >
      <BrowserRouter>
        <App />
      </BrowserRouter>
      </RainbowKitProvider>
    </WagmiConfig>
    {/* </WagmiConfig> */}
  </React.StrictMode>
);
