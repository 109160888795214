import {
  Input,
  Popover,
  Radio,
  Modal,
  message,
  Button,
  Form,
  InputNumber,
  Row,
  Col,
  Spin,
} from "antd";
import {
  ArrowDownOutlined,
  SettingOutlined,
  WalletOutlined,
} from "@ant-design/icons";
import { useState, useEffect, useCallback } from "react";
import tokenList from "../tokenList.json";
import axios from "axios";

import {
  useSendTransaction,
  useWaitForTransaction,
  useAccount,
} from "wagmi";

import {
  callSwapApi,
  cutAfterDecimal,
  fetchSymbolInfo,
  toPlainString,
} from "../apis/api";
import { routerAddress, router_abi, token_abi } from "../config/router_abi";
import Web3 from "web3";
import toast, { Toaster } from "react-hot-toast";
import notFoundImage from "../not-found.png";
// import { estimateGas, fetchFeeData} from '@wagmi/core'
import {
  writeContract,
  waitForTransaction,
  fetchBalance,
  readContract,
} from "@wagmi/core";



function Swap(props) {
  const { address, isConnected, chainId } = useAccount();
  const [web3, setWeb3] = useState(null);
  const [tokenLoader, setTokenLoader] = useState(false);
  const [slippage, setSlippage] = useState(0.1);
  const [messageApi, contextHolder] = message.useMessage();
  const [tokenOneAmount, setTokenOneAmount] = useState("");
  const [tokenTwoAmount, setTokenTwoAmount] = useState(0);
  const [tokenOne, setTokenOne] = useState(tokenList[0]);
  const [tokenTwo, setTokenTwo] = useState(tokenList[1]);
  const [isOpen, setIsOpen] = useState(false);
  const [changeToken, setChangeToken] = useState(1);
  const [prices, setPrices] = useState({});
  const [isChecked, setIsChecked] = useState(false);
  const [txDetails, setTxDetails] = useState({
    to: null,
    data: null,
    value: null,
  });
  const [allTxDetails, setAllTxDetails] = useState({});
  const [showInput, setShowInput] = useState(false); // State to manage input visibility
  const [customValue, setCustomValue] = useState(1);

  const [searchInput, setSearchInput] = useState("");
  const [loader, setLoader] = useState(false);

  const [networkFee, setNetworkFee] = useState(0);

  const { data, sendTransaction } = useSendTransaction({
    request: {
      from: address,
      to: String(txDetails.to),
      data: String(txDetails.data),
      value: String(txDetails.value),
    },
  });

  const { isLoading, isSuccess } = useWaitForTransaction({
    hash: data?.hash,
  });
  const [filteredTokens, setFilteredToken] = useState(tokenList);
  


  const handleProcessRoute = async () => {
    setLoader(true);
    try {
      const web3Instance = new Web3(window.ethereum);
      const contractAddress = allTxDetails.routeProcessorAddr;
      const contractInstance = new web3Instance.eth.Contract(
        router_abi,
        contractAddress
      );
      const payableAmount = allTxDetails?.routeProcessorArgs?.value || 0;
      const tokenInAddress = allTxDetails?.routeProcessorArgs?.tokenIn;
      const amountIn = allTxDetails?.routeProcessorArgs?.amountIn;
      const tokenOutAddress = allTxDetails?.routeProcessorArgs?.tokenOut;
      const amountOutMin = allTxDetails?.routeProcessorArgs?.amountOutMin;
      const toAddress = allTxDetails?.routeProcessorArgs?.to;
      const route = allTxDetails?.routeProcessorArgs?.routeCode;
      const gasPrice = await web3Instance.eth.getGasPrice();

      const options = {
        from: address,
        value: payableAmount,
      };
      const ethBal = await fetchBalance({
        address: address,
        chainId: 1,
      });

      const ethBalance = ethBal.formatted;
      console.log(`ethbalance${ethBalance}`);

      try {
        if (tokenOne.ticker === "ETH") {
          const inst = { address: contractAddress, abi: router_abi };
          const actualAmount = amountIn / 10 ** tokenOne.decimals;
          const bal = ethBalance - (ethBalance * 10) / 100;

          let amountOutMinDecimal = toPlainString(
            (Number(amountOutMin) / 10 ** tokenTwo.decimals)- toPlainString(Number(amountOutMin) / 10 ** tokenTwo.decimals *(slippage/100)));
            // amountOutMin / 10 ** tokenTwo.decimals -
            // ((amountOutMin / 10 ** tokenTwo.decimals) * (slippage / 100));
            console.log(amountOutMinDecimal + "amountOutMinDecimal");

           

          let amountOutMinNew = parseInt(amountOutMinDecimal * (10 ** tokenTwo.decimals));
        
          console.log(`${toPlainString(amountOutMinNew)}amountOutMinNew`);

          if (actualAmount <= bal) {
            const res = await writeContract({
              mode: "recklesslyUnprepared",
              abi: router_abi,
              address: contractAddress,
              functionName: "processRoute",
              // args:["0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE",toPlainString(1000000000000000),"0x111111111117dC0aa78b770fA6A738034120C302",toPlainString(8710490518114516936), "0xa75a487b8cCF335f35BD11bbeFB6f82c9BffacBd", "0x0301ffff020186f518368E0d49d5916e2BD9EB162E9952b7b04dC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc204C02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc20086f518368E0d49d5916e2BD9EB162E9952b7b04d00a75a487b8cCF335f35BD11bbeFB6f82c9BffacBd000bb8"],
              args: [
                tokenInAddress,
                amountIn,
                tokenOutAddress,
                toPlainString(amountOutMinNew),
                toAddress,
                route,
              ],
              value: payableAmount,
            });
            // await waitfunction(transaction.hash);
            // res.wait();
            console.log(`${res}`);
            // transaction.wait()
            toast.success("Transaction Successful.");
          } else {
            toast.error("Insufficient fund for gas fees.");
          }
          // console.log("Transaction Successful:", transaction);
        } else {
          // const tokenInstance = new web3Instance.eth.Contract(
          //   token_abi,
          //   tokenInAddress
          // );

          const dt = await readContract({
            abi: token_abi,
            address: tokenInAddress,
            functionName: "allowance",
            args: [address, contractAddress],
          });
          // console.log(`${Number(dt)}::::${Number(amountIn)}allowance`)

          // const allowance = await tokenInstance.methods
          //   .allowance(accounts, contractAddress)
          //   .call();
          

          if (Number(dt) >= Number(amountIn)) {
            // const transaction = await contractInstance.methods
            //   .processRoute(
            //     tokenInAddress,
            //     amountIn,
            //     tokenOutAddress,
            //     amountOutMin,
            //     toAddress,
            //     route
            //   )
            //   .send(options);
            if (ethBalance > networkFee) {
              let amountOutMinDecimal = toPlainString(
                (Number(amountOutMin) / 10 ** tokenTwo.decimals)- toPlainString(Number(amountOutMin) / 10 ** tokenTwo.decimals *(slippage/100)));
                // amountOutMin / 10 ** tokenTwo.decimals -
                // ((amountOutMin / 10 ** tokenTwo.decimals) * (slippage / 100));
                // console.log(amountOutMinDecimal + "amountOutMinDecimalt1");
    
               
    
              let amountOutMinNew = parseInt(amountOutMinDecimal * (10 ** tokenTwo.decimals));
            
              // console.log(`${toPlainString(amountOutMinNew)}amountOutMinNewt1`);
    
              const res = await writeContract({
                mode: "recklesslyUnprepared",
                abi: router_abi,
                address: contractAddress,
                functionName: "processRoute",
                // args:["0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE",toPlainString(1000000000000000),"0x111111111117dC0aa78b770fA6A738034120C302",toPlainString(8710490518114516936), "0xa75a487b8cCF335f35BD11bbeFB6f82c9BffacBd", "0x0301ffff020186f518368E0d49d5916e2BD9EB162E9952b7b04dC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc204C02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc20086f518368E0d49d5916e2BD9EB162E9952b7b04d00a75a487b8cCF335f35BD11bbeFB6f82c9BffacBd000bb8"],
                args: [
                  tokenInAddress,
                  amountIn,
                  tokenOutAddress,
                  toPlainString(amountOutMinNew),
                  toAddress,
                  route,
                ],
                value: payableAmount,
              });
              console.log("Transaction successful:", res);
              toast.success("Transaction Successful.");
            } else {
              toast.error("Insufficient fund for gas fees.");
            }
          } else {
            const data = await writeContract({
              mode: "recklesslyUnprepared",
              address: tokenInAddress,
              abi: token_abi,
              functionName: "approve",
              args: [contractAddress, amountIn],
            });
            console.log(data, ":::");
            const transactionReceipt = waitForTransaction({
              hash: data.hash,
            });

            if (transactionReceipt) {
              // const transaction = await contractInstance.methods
              //   .processRoute(
              //     tokenInAddress,
              //     amountIn,
              //     tokenOutAddress,
              //     amountOutMin,
              //     toAddress,
              //     route
              //   )
              //   .send(options);
              if (ethBalance > networkFee) {
                let amountOutMinDecimal = toPlainString(
                  (Number(amountOutMin) / 10 ** tokenTwo.decimals)- toPlainString(Number(amountOutMin) / 10 ** tokenTwo.decimals *(slippage/100)));
                  // amountOutMin / 10 ** tokenTwo.decimals -
                  // ((amountOutMin / 10 ** tokenTwo.decimals) * (slippage / 100));
                  console.log(amountOutMinDecimal + "amountOutMinDecimalt");
      
                 
      
                let amountOutMinNew = parseInt(amountOutMinDecimal * (10 ** tokenTwo.decimals));
              
                console.log(`${toPlainString(amountOutMinNew)}amountOutMinNewt`);
      

                const res = await writeContract({
                  mode: "recklesslyUnprepared",
                  abi: router_abi,
                  address: contractAddress,
                  functionName: "processRoute",
                  // args:["0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE",toPlainString(1000000000000000),"0x111111111117dC0aa78b770fA6A738034120C302",toPlainString(8710490518114516936), "0xa75a487b8cCF335f35BD11bbeFB6f82c9BffacBd", "0x0301ffff020186f518368E0d49d5916e2BD9EB162E9952b7b04dC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc204C02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc20086f518368E0d49d5916e2BD9EB162E9952b7b04d00a75a487b8cCF335f35BD11bbeFB6f82c9BffacBd000bb8"],
                  args: [
                    tokenInAddress,
                    amountIn,
                    tokenOutAddress,
                    toPlainString(amountOutMinNew),
                    toAddress,
                    route,
                  ],
                  // value:payableAmount
                });
                console.log("Transaction successful:", res);
                toast.success("Transaction Successful.");
              }
            } else {
              toast.error("Insufficient fund for gas fees.");
            }
          }
        }
      } catch (error) {
        setLoader(false);
        if (error.code === -32603) {
          // setLoader(false);
          // User rejected the transaction
          console.error("Error processing route:", error);
          toast.error("UniswapV2: INSUFFICIENT_OUTPUT_AMOUNT");
        } else {
          console.error("Error processing route:", error);
          toast.error("Transaction rejected by user.");
        }
      }
      fetchTokenBalances();
      setTokenOneAmount("");
      setTokenTwoAmount("");
      setLoader(false);
    } catch (error) {
      setLoader(false);
      console.error("Error connecting to Ethereum:", error);
      toast.error("Error connecting to Ethereum. Please try again.");
    }
  };

  async function getTokenDetails(tokenAddress) {
    try {
     

      const name = await readContract({
        abi: token_abi,
        address: tokenAddress,
        functionName: "name",
        args: [],
      });

      const symbol = await readContract({
        abi: token_abi,
        address: tokenAddress,
        functionName: "symbol",
        args: [],
      });

      const decimals = await readContract({
        abi: token_abi,
        address: tokenAddress,
        functionName: "decimals",
        args: [],
      });


      return {
        name: name,
        ticker: symbol,
        decimals: decimals,
        address: tokenAddress,
        img: notFoundImage,
      };
    } catch (error) {
      toast.error("Token Address is not correct.")
      console.error("Error fetching token details:", error);
    }
  }

  useEffect(() => {
    const initWeb3 = async () => {
      if (window.ethereum) {
        const web3Instance = new Web3(window.ethereum);
        try {
          setWeb3(web3Instance);

          // const accounts = await web3Instance.eth.getAccounts();
          // setAccount(accounts[0]);

       
        } catch (error) {
          console.error("Error while connecting to Ethereum:", error);
        }
      } else {
        console.error("Web3 provider not detected");
      }
    };

    // if (web3) {
    initWeb3();
    // }
  }, [isConnected]);
  const fetchTokenBalances = async () => {
    try {
   
      let balance1;
      let balance2;
      console.log(`${address}:address`);

      if (tokenOne.ticker == "ETH") {
        
        const data = await fetchBalance({
          address: address,
        });
        balance1 = data.formatted;
      } else {
      
        const data = await fetchBalance({
          address: address,
          token: tokenOne.address,
        });
        balance1 = data.formatted;
      }

      if (tokenTwo.ticker == "ETH") {
      
        const data = await fetchBalance({
          address: address,
        
        });
        balance2 = data.formatted;
      } else {
      
        const data = await fetchBalance({
          address: address,
          token: tokenTwo.address,
        });
        balance2 = data.formatted;
      }


      var token1 = { ...tokenOne };
      var token2 = { ...tokenTwo };
      const bal1 = Number(balance1) > 0 ? Number(balance1) : 0;
      const bal2 = Number(balance2) > 0 ? Number(balance2) : 0;
      token1["balance"] = bal1 > 0 ? bal1?.toFixed(3) : 0;
      token2["balance"] = bal2 > 0 ? bal2?.toFixed(3) : 0;
      // if(token1 && token2){
      setTokenOne(token1);
      setTokenTwo(token2);
      // consol
      // setBalance([balance1,balance2])

      // console.log(`Balance 1: ${balance1}`);
      // console.log(`Balance 2: ${balance2}`);
      // console.log(`Balance 2: ${token1}`);
      // }
    } catch (error) {
      // toast.error(`${error}`);
      console.error("Error fetching token balances:", error);
      // Handle error state, e.g., show error message to user
    }
  };

  useEffect(() => {
    // Call fetchTokenBalances when web3 or its dependencies change
    if (web3 && tokenOne.address && tokenTwo.address) {
      fetchTokenBalances();
    }
  }, [web3, tokenOne.address, tokenTwo.address, isConnected, address]); // Depend only on web3, tokenOne.address, and tokenTwo.address

  // useEffect(() => {

  //   const tokenInstance = new web3.eth.Contract(tokenABI, tokenAddress);
  //   setTokenContract(tokenInstance);

  //   // Fetch token balance
  //   const balance = await tokenInstance.methods.balanceOf(accounts[0]).call();
  //   setTokenBalance(balance);
  // }, [web3]);

  // const { write } = useContractWrite({
  //   address: allTxDetails.routeProcessorAddr,
  //   abi: router_abi,
  //   functionName: "processRoute",
  //   args: [
  //     0,
  //     allTxDetails?.routeProcessorArgs?.tokenIn,
  //     allTxDetails?.routeProcessorArgs?.amountIn,
  //     allTxDetails?.routeProcessorArgs?.tokenOut,
  //     allTxDetails?.routeProcessorArgs?.amountOutMin,
  //     address,
  //     allTxDetails?.routeProcessorArgs?.routeCode,
  //   ],
  //   onError(error) {
  //     console.log('Error', error)
  //   },
  // });

  //   async function getBalanceUser(address, UsdtToken) {
  //   try {
  //     const balance = await getBalance({
  //       address: address,
  //     });
  //     return balance;
  //   } catch (err) {
  //     console.log(err);
  //   }
  // }

  const handleSlippage = (e) => {
    setSlippage(e.target.value);
  };

  // const changeAmount = (e) => {
  //   setTokenOneAmount(e.target.value)
  //   if(e.target.value && prices) {
  //     setTokenTwoAmount((e.target.value * prices.ratio).toFixed(2))
  //   } else
  //     setTokenTwoAmount(0)
  // }
  // const changeAmount = async (e) => {
  //   setTokenOneAmount(e.target.value);
  //   if (e.target.value !== "" || e.target.value != 0) {
  //     const provider = new ethers.providers.Web3Provider(window.ethereum);
  //     const gasPrice = await provider.getGasPrice();
  //     console.log(provider._network);
  //     var params = {
  //       chainId: provider._network["chainId"],
  //       tokenIn: tokenOne.address,
  //       tokenOut: tokenTwo.address,
  //       amount: toPlainString(e.target.value * 10 ** Number(tokenOne.decimals)),
  //       maxPriceImpact: "0.001",
  //       gasPrice: gasPrice,
  //       to: address,
  //       preferSushi: true,
  //     };
  //     var data = await callSwapApi(params);
  //     console.log(`${data.assumedAmountOut}:::::datatt:::::`);
  //     setTokenTwoAmount(
  //       cutAfterDecimal(
  //         toPlainString(
  //           data.assumedAmountOut / 10 ** Number(tokenTwo.decimals)
  //         ),
  //         6
  //       )
  //     );
  //     setAllTxDetails(data);
  //     setArgs({});
  //   }
  // };
  const isValidNumber = (value) => {
    // Regular expression to match valid numbers (including decimals)
    const numberRegex = /^\d*\.?\d*$/;
    return numberRegex.test(value);
  };
  function debounce(func, wait) {
    let timeout;
    return function (...args) {
      const context = this;
      clearTimeout(timeout);
      timeout = setTimeout(() => func.apply(context, args), wait);
    };
  }

  const changeAmount = useCallback(
    debounce(async (e) => {
      const value = e?.target?.value;

      // Validate input

      if (!isValidNumber(value) || e.target.value == "") {
        // Handle invalid input
        console.log("hello");
        setTokenOneAmount("");
        setTokenTwoAmount("");
        setAllTxDetails({});
        // setTimeout(()=>{
        //   setTokenTwoAmount("");
        // },1000)
        // setArgs({});
        return;
      }

      const amount = parseFloat(value);
      // if (amount <= 0) {
      //   console.log("hello1");
      //   // Handle zero or negative amount
      //   setTokenOneAmount(""); // Optionally clear input field
      //   setTokenTwoAmount("");
      //   setAllTxDetails({});
      //   // setArgs({});
      //   return;
      // }

      try {
        setTokenOneAmount(e.target.value);
        // setTokenTwoAmount("");

        const web3Instance = new Web3(window.ethereum);
        const gasPrice = await web3Instance.eth.getGasPrice();

        const params = {
          chainId: 1, // Use provider.network.chainId directly
          tokenIn: tokenOne.address,
          tokenOut: tokenTwo.address,
          amount: toPlainString(amount * 10 ** Number(tokenOne.decimals)),
          maxPriceImpact: "0.001",
          // gasPrice: gasPrice,
          to: address,
          preferSushi: false,
        };

        const data = await callSwapApi(params);
        // console.log(data);
        setAllTxDetails(data);
        if (data.status == "NoWay") {
          setTokenTwoAmount(0);
        } else {
          setTokenTwoAmount(
            cutAfterDecimal(
              toPlainString(
                data.assumedAmountOut / 10 ** Number(tokenTwo.decimals)
              ),
              6
            )
          );

          // if(isConnected){
          try {
            // estimateGas()

            // console.log(`${data.routeProcessorAddr}`);
            let { routeProcessorAddr, routeProcessorArgs, gasSpent } = data;
            // const contractAddress = routeProcessorAddr;
            // const contractInstance = new web3Instance.eth.Contract(
            //   router_abi,
            //   contractAddress
            // );

            // const payableAmount = routeProcessorArgs?.value || 0;
            // const tokenInAddress = routeProcessorArgs?.tokenIn;
            // const amountIn =routeProcessorArgs?.amountIn;
            // const tokenOutAddress = routeProcessorArgs?.tokenOut;
            // const amountOutMin = routeProcessorArgs?.amountOutMin;
            // const toAddress = routeProcessorArgs?.to;
            // const route = routeProcessorArgs?.routeCode;
            // console.log(`${gasPrice}, blochckain gasPrice `,)
            // console.log(`${tokenInAddress}::${amountIn}:::${tokenOutAddress}::::${amountOutMin}:::${toAddress},::${route}::::payabke:::${payableAmount}`,"  all params");
            // const gasLimit = await contractInstance.methods
            // .processRoute(tokenInAddress, amountIn, tokenOutAddress, amountOutMin, toAddress, route)
            // .estimateGas({
            //   from: toAddress,
            //   value: Number(payableAmount)>0?toPlainString((payableAmount)):'0',
            //   gasPrice: gasPrice,
            // })
            // console.log(`${gasPrice}`);
            // console.log(`${gasSpent}`)
            const transactionFee = toPlainString(
              (Number(gasPrice) * Number(gasSpent)) / 1e18
            );
            setNetworkFee(transactionFee);
            // const formattedFee = web3Instance.utils.fromWei(transactionFee.toString(), 'ether');
            // console.log(`${transactionFee}formattedFee`);
          } catch (error) {
            console.log(`${error}`);
          }
          // }
        }

        if (value == "") {
          setTokenTwoAmount("");
        }

        // setArgs({});
      } catch (error) {
        setTokenTwoAmount("");
        console.error("Error fetching data:", error);
        // Handle error state, e.g., show error message to user
      }
    }, 50),
    [tokenOne.address, tokenTwo.address, tokenOneAmount ,address]
  );

  useEffect(() => {
    let intervalId; 

    if (tokenOneAmount !== "" && tokenOneAmount !== 0) {
    
      intervalId = setInterval(() => {
        changeAmount({ target: { value: tokenOneAmount } });
      }, 5000);
    }

    return () => {
      if (intervalId) {
        clearInterval(intervalId); // Clear interval on component unmount or when tokenOneAmount changes
      }
    };
  }, [changeAmount, tokenOneAmount]);

  useEffect(() => {
    if (tokenOneAmount === "") {
      const timeoutId = setTimeout(() => {
        setTokenTwoAmount("");
      }, 1000);

      return () => clearTimeout(timeoutId);
    }
  }, [tokenOneAmount, setTokenTwoAmount]);

  const switchTokens = () => {
    setPrices(null);
    setTokenOneAmount("");
    setTokenTwoAmount("");
    setTokenOne(tokenTwo);
    setTokenTwo(tokenOne);
    fetchDexSwap(tokenTwo.address, tokenOne.address);
  };

  const openModal = (token) => {
    setChangeToken(token);
    setIsOpen(true);
  };

  const filterToken = async (e) => {
    setTokenLoader(true)
    const searchTerm = e.trim().toLowerCase(); // Convert search term to lowercase and trim whitespace
    // console.log(`Searching for: ${searchTerm}`);

    if (searchTerm === "") {
      // If search term is empty, reset to original tokenList
      setFilteredToken(tokenList);
      setTokenLoader(false);
      return;
    }

    if (searchTerm.startsWith("0x")) {
     
      getTokenDetails(searchTerm)
        .then(async (details) => {
          if (details) {
            let det = { ...details };
            let img = await fetchSymbolInfo(details["ticker"]);
            if (img["logo"]) {
              det["img"] = img["logo"];
            }
            setFilteredToken([det, ...tokenList]); 
            console.log(`${details.ticker} details`);
          }
          setTokenLoader(false);
        })
        .catch((error) => {
          console.log("Error fetching token details:", error);
          setTokenLoader(false);

          // Handle error (e.g., display error message to the user)
        });
      return;
    }

    // Filter tokenList based on searchTerm
    const filteredToken = tokenList.filter((ele) => {
      
      return (
        (ele.ticker && ele.ticker.toLowerCase().includes(searchTerm)) ||
        (ele.name && ele.name.toLowerCase().includes(searchTerm))
      );
    });

    if (filteredToken.length == 0) {
      setFilteredToken(tokenList);
    } else {
      setFilteredToken(filteredToken);
    }
    setTokenLoader(false);

  };

  const modifyToken = (i) => {
    setPrices(null);
    setTokenOneAmount(0);
    setTokenTwoAmount(0);
    setSearchInput("");
    // filterToken("");
    if (changeToken === 1) {
      if (filteredTokens[i]["address"] != tokenTwo.address) {
        setTokenOne(filteredTokens[i]);
        fetchDexSwap(filteredTokens[i].address, filteredTokens.address);
      }
    } else {
      if (filteredTokens[i]["address"] != tokenOne.address) {
        setTokenTwo(filteredTokens[i]);
        fetchDexSwap(tokenOne.address, filteredTokens[i].address);
      }
    }
    setIsOpen(false);
  };

  const fetchDexSwap = async (one, two) => {
    // const res = await axios.get(
    //   "https://dex-swap-clone.onrender.com/tokenPrice",
    //   {
    //     params: {
    //       addressOne: one,
    //       addressTwo: two,
    //     },
    //   }
    // );
    // console.log(res.data);
    setPrices(0);
  };
  const handleCustomClick = () => {
    setShowInput(true); // Show the input field when button is clicked
  };

  const handleInputChange = (event) => {
    let value = event.target.value; 
   
    value = parseFloat(value);

    
    if(value==""){
      setCustomValue(value); 
    }
    else if (value < 0.1) {
      setCustomValue(0.1); 
    } else if (value > 100) {
      setCustomValue(100); 
    } else {
      setCustomValue(value);
    }
    // }
  };

  const handleConfirm = () => {
    
    console.log("Custom value confirmed:", customValue);
    if ( isNaN(Number(customValue))==false) {
      console.log(`${customValue}::::customvalue`);
      setSlippage(customValue);
    }
    setShowInput(false); // Hide the input field after confirming
  };

 


  useEffect(() => {
    fetchDexSwap(tokenList[0].address, tokenList[1].address);
    
  }, []);

  useEffect(() => {
    messageApi.destroy();
    if (isLoading) {
      messageApi.open({
        content: "Waiting for transaction to be mined",
        type: "loading",
        duration: 0,
      });
    }
  }, [isLoading]);

  useEffect(() => {
    messageApi.destroy();
    if (isSuccess) {
      messageApi.open({
        type: "success",
        content: "Transaction Success",
        duration: 2,
      });
    } else if (txDetails.to) {
      messageApi.open({
        type: "error",
        content: "Transaction Failed",
        duration: 2,
      });
    }
  }, [isSuccess]);

  useEffect(() => {
    if (txDetails.to && isConnected) {
      sendTransaction();
      message.success("Transaction sent");
    }
  }, [txDetails]);

  const settingsContent = (
    <>
      <div>Slippage Tolerance</div>
      <div>
        <Radio.Group onChange={handleSlippage} value={slippage}>
          <Radio.Button value={0.1}>0.1%</Radio.Button>
          <Radio.Button value={0.5}>0.5%</Radio.Button>
          <Radio.Button value={1}>1%</Radio.Button>
        </Radio.Group>
        <Button
          onClick={() => {
            handleCustomClick();
          }}
          type="primary"
          style={{ background: "#21273a", borderColor: "#fff" }}
        >
          Custom
        </Button>
        {showInput && (
      
          <Row justify="end" style={{ marginTop: "10px" }}>
            <Col>
              <input
                type="number"
                min={1}
                max={100}
                step={0.1}
                value={customValue}
                onChange={handleInputChange}
                style={{ width: "50px", height: "32px", borderRadius: "5px" }}
                // onPressEnter={handleConfirm}
              />
            </Col>
            <Col>
              <Button onClick={handleConfirm} style={{ marginLeft: "10px" }}>
                Confirm
              </Button>
            </Col>
          </Row>
          //   </Form.Item>
          // </Form>
        )}
      </div>
    </>
  );

  return (
    <>
      {contextHolder}
      <div className="d-flex flex-column">
        <h1 className="headingText">Shitty Swap</h1>
        <Modal
          open={isOpen}
          footer={null}
          onCancel={() => {
            setIsOpen(false);
          }}
          title="Select a token"
        >
          <div className="px-3">
            <input
              type="Search token"
              className="searchBox"
              placeholder="Search Token"
              value={searchInput}
              onChange={(e) => {
                setSearchInput(e.target.value);
                filterToken(e.target.value);
              }}
            />
          </div>
          <div className="modalContent">
            {tokenLoader==true?<div className="d-flex justify-content-center align-items-center" style={{width:"100%",height:"100%"}}>
              <Spin size="large" style={{color:"red"}} />
            </div>:
            filteredTokens?.map((token, index) => {
              return (
                <div
                  className="tokenChoice"
                  key={index}
                  onClick={() => {
                    if (searchInput.startsWith("0x")) {
                      modifyToken(index);
                    } else {
                      modifyToken(index);
                      filterToken("");
                    }
                  }}
                >
                  <img
                    src={token.img}
                    alt={token.ticker}
                    className="tokenLogo"
                  />
                  <div className="tokenChoiceNames">
                    <div className="tokenName">{token.name}</div>
                    <div className="tokenTicker">{token.ticker}</div>
                  </div>
                </div>
              );
            })}
          </div>
        </Modal>
        <div className="tradeBox">
          <div className="tradeBoxHeader">
            <h4>Swap</h4>
            <Popover
              title="Settings"
              trigger="click"
              placement="bottomRight"
              content={settingsContent}
            >
              <SettingOutlined className="cog" />
            </Popover>
          </div>
          <div className="inputs">
            <Input
              placeholder="0"
              value={tokenOneAmount}
              onChange={changeAmount}
              // disabled={!prices}
            />
            {/* <p>hello</p> */}
            <Input placeholder="0" value={tokenTwoAmount} disabled={true} />
            <div className="switchButton" onClick={switchTokens}>
              <ArrowDownOutlined className="switchArrow" />
            </div>

            <div className="assetOne" onClick={() => openModal(1)}>
              <img
                src={tokenOne.img}
                alt="assetOnelogo"
                className="assetlogo"
              />
              {/* {tokenOne.ticker} */}
              <p className="m-0 text-right ">{tokenOne.ticker}</p>
            </div>

            <div className="assetTwo" onClick={() => openModal(2)}>
              <img
                src={tokenTwo.img}
                alt="assetTwologo"
                className="assetlogo"
              />

              <p className="m-0 text-right">{tokenTwo.ticker}</p>
            </div>
            <div className="balance1">
              <button
                className="maxbtn"
                onClick={() => {
                  if (tokenOne.ticker == "ETH") {
                    setTokenOneAmount(
                      tokenOne["balance"] - (tokenOne["balance"] * 10) / 100
                    );
                    changeAmount({
                      target: {
                        value:
                          tokenOne["balance"] -
                          (tokenOne["balance"] * 10) / 100,
                      },
                    });
                  } else {
                    setTokenOneAmount(tokenOne["balance"]);
                    changeAmount({ target: { value: tokenOne["balance"] } });
                  }
                }}
              >
                Max
              </button>

              <WalletOutlined className="walletIcon" />

              <p className="m-0 text-right">
                {/* {(tokenOne["balance"] == 0 || isNaN(Number(tokenOne["balance"]))
                ? 0?.toFixed(2)
                : (
                    Number(tokenOne["balance"]) /
                    10 ** tokenOne.decimals
                  )?.toFixed(3)
              )?.toString()}{" "} */}
                {isConnected ? tokenOne["balance"] : 0}
              </p>
            </div>
            <div className="balance2" onClick={() => openModal(2)}>
              <WalletOutlined className="walletIcon" />

              <p className="m-0 text-right">
                {/* {(tokenTwo["balance"] == 0 || isNaN(Number(tokenTwo["balance"]))
                ? 0?.toFixed(2)
                : Number(tokenTwo["balance"]) /
                  (10 ** tokenTwo.decimals)?.toFixed(3)
              )?.toString()}{" "} */}
                {isConnected ? tokenTwo["balance"] : 0}
              </p>
            </div>
          </div>
          {loader ? (
            <div className="swapButton">
              <div
                class="spinner-border  spinner-border-sm"
                role="status"
              ></div>{" "}
              &nbsp;
              <div>Processing...</div>
            </div>
          ) : (
            <div
              className="swapButton"
              onClick={() => {
                console.log(`${allTxDetails?.priceImpact * 100 <= 14}`);
                console.log(
                  `${allTxDetails?.priceImpact * 100 > 14 && isChecked}`
                );
                if (
                  (isConnected && allTxDetails?.priceImpact * 100 <= 14) ||
                  (allTxDetails?.priceImpact * 100 > 14 &&
                    isChecked &&
                    tokenOne["balance"] >= tokenOneAmount)
                ) {
                  handleProcessRoute();
                } else {
                  console.log("test");
                }
              }}
              disabled={
                !tokenOneAmount ||
                !isConnected ||
                allTxDetails?.priceImpact * 100 >= 14
                  ? isChecked == false
                    ? true
                    : false
                  : false || tokenOne["balance"] < tokenOneAmount
              }
            >
              {tokenOne["balance"] < tokenOneAmount
                ? "Insufficient Fund"
                : "Swap"}
            </div>
          )}

          <div
            className={
              (tokenOneAmount != "" || tokenOneAmount != 0) &&
              tokenTwoAmount != ""
                ? "showCard"
                : "hideCard"
            }
          >
            {allTxDetails?.priceImpact * 100 > 14 ? (
              <div className="row">
                <div
                  className="col-12 card "
                  style={{ background: "rgba(255, 0, 0, 0.2)" }}
                >
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      checked={isChecked}
                      id="flexCheckDefault"
                      onChange={(e) => {
                        console.log(e.target.checked);
                        setIsChecked(e.target.checked);
                      }}
                    />
                    <label
                      className="form-check-label text-danger"
                      for="flexCheckDefault"
                    >
                      Price impact is too high. You will lose a big portion of
                      your funds in this trade. Please tick the box if you would
                      like to continue.
                    </label>
                  </div>
                  {/* <p class="text-danger">
                </p> */}
                </div>
              </div>
            ) : null}
            <div className="row">
              <div className="col-6">Price impact</div>
              <div className="Col-6">
                {allTxDetails?.priceImpact
                  ? cutAfterDecimal(allTxDetails?.priceImpact * 100, 4)
                  : 0}{" "}
                %
              </div>
            </div>
            <div className="row">
              <div className="col-6">Estimated Recieved</div>
              <div className="Col-6">
                {allTxDetails?.assumedAmountOut
                  ? cutAfterDecimal(toPlainString(
                      Number(allTxDetails?.assumedAmountOut) / 10 ** tokenTwo.decimals
                    ),6)
                  : 0}{" "}
                {tokenTwo.ticker}
              </div>
            </div>
            <div className="row">
              <div className="col-6">Minimum Recieved</div>
              <div className="Col-6">
                { 
               allTxDetails?.routeProcessorArgs?.amountOutMin?
                  cutAfterDecimal(
                    toPlainString(
                      (Number(allTxDetails?.routeProcessorArgs?.amountOutMin) / 10 ** tokenTwo.decimals)- toPlainString(Number(allTxDetails?.routeProcessorArgs?.amountOutMin) / 10 ** tokenTwo.decimals *(slippage/100)))
                      ,6):0
                    
                   
                  }{" "}
                {tokenTwo.ticker}
              </div>
            </div>
            <div className="row">
              <div className="col-6">Network Fees</div>
              <div className="Col-6">
                {cutAfterDecimal(networkFee, 4)}&nbsp; ETH
              </div>
            </div>
            {/* <div className="row">
            <div className="col-6">Network fee</div>
            <div className="Col-6">-0.18%</div>
          </div> */}
            {/* <div className="row">
              <div className="col-6">Routing source</div>
              <div className="Col-6">SushiSwap API</div>
            </div> */}

            <div className="row">
              <div className="col-6">Route</div>
              <div className="Col-6" style={{ color: "blue" }}>
                <button
                  type="button"
                  data-bs-toggle="modal"
                  style={{
                    color: "blue",
                    background: "transparent",
                    border: 0,
                  }}
                  data-bs-target="#exampleModal"
                >
                  {/* Launch demo modal */}
                  View
                </button>
                {/* View */}
              </div>
            </div>
            {/* <hr /> */}
          </div>
        </div>

        {/* <button type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#exampleModal">
  Launch demo modal
</button> */}
      </div>
      <div
        className="modalRoute modal fade"
        id="exampleModal"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-scrollable modal-dialog-centered">
          <div className="modal-content" style={{ backgroundColor: "#243056" }}>
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="exampleModalLabel">
                Route
              </h1>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body" style={{ fontSize: "14px" }}>
              <h6 className="pb-2" style={{ fontSize: "14px" }}>
                Our routing system automatically splits your trade across
                various pools to get you the best price.
              </h6>
              <div>
                <div
                  className="card"
                  style={{
                    maxHeight: "400px",
                    overflowY: "auto",
                    overflowX: "hidden",
                  }}
                >
                  {allTxDetails?.route ? (
                    allTxDetails?.route?.map((ele, i) => {
                      return (
                        <>
                          <div className="row" key={i.toString() + "abc"}>
                            <div className="col-3">
                              {allTxDetails.tokens[ele.tokenFrom].symbol}
                            </div>
                            <div className="col-6">
                              <p className="text-center">
                                {(ele.share * 100).toFixed(0)}%
                              </p>
                              <p
                                className="text-center"
                                style={{ fontSize: "12px" }}
                              >
                                {ele.poolName}
                              </p>
                            </div>
                            <div className="col-3">
                              {allTxDetails.tokens[ele.tokenTo].symbol}
                            </div>
                          </div>
                        </>
                      );
                    })
                  ) : (
                    <div className="card">
                      <p className="text-center">No Route Found.</p>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn y w-100"
                data-bs-dismiss="modal"
                style={{ background: "#090d17", color: "#fff" }}
              >
                Close
              </button>
    
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Swap;
